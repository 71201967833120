<!--
 * @Author: yuwenwen
 * @Date: 2022-12-01 20:30:19
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-01-06 11:41:42
 * @FilePath: \nc-funeral-mobile\src\views\obituary\leaveMessage.vue
-->
<template>
  <div class="reserve-transport leave-message-page">
    <div>
      <div class="title-box">
        <div class="title-text">您可以在此留言追思，衷心感谢</div>
      </div>
      <div class="leave-message-box">
        <van-field
          v-model.trim="content"
          placeholder="请输入留言"
          rows="9"
          autosize
          type="textarea"
          maxlength=60
          show-word-limit=true
        ></van-field>
      </div>
    </div>
    <div class="btn-box">
      <div class="btn" @click="addObituaryComments">送出</div>
    </div>
  </div>
</template>
<script>
import { addObituaryComments } from "@/api/obituary.js";
import { showFailToast, showSuccessToast } from "vant";
export default {
  name: "LeaveMessage",
  data() {
    return {
      content: "",
      obituaryId: "",
    };
  },
  created() {
    let { id } = this.$route.query;
    this.obituaryId = id;
  },
  methods: {
    async addObituaryComments() {
      if (this.content) {
        let data = {
          contentId: this.obituaryId,
          content: this.content,
        };
        let [err, res] = await this.$awaitWrap(addObituaryComments(data));
        if (err) {
          showFailToast("添加失败");
          return;
        }
        if (res.code == 200) {
          showSuccessToast(res.result);
          this.$router.go(-1);
        }
      }else{
        showFailToast("请输入留言内容");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.leave-message-page {
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f7f8f9 !important;
}
.title-box {
  height: 45px !important;
  .title-text {
    font-size: 14px !important;
  }
}
.leave-message-box {
  height: 210px;
  background: white;
}
@import "../../assets/styles/reserve.less";
</style>